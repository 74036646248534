import $2 from "jquery";
window.$2 = $2;
import 'simplebar/dist/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick';

import { Canvas, Rect, FabricImage, Object, Control, util } from 'fabric';
import { getRecaptcha } from "./forms/form";
// import 'jsuites';
import Cropper from '@jsuites/cropper'

"use strict";

let lang = 'pl';
let canvas;
let source;
let assetIndex = 0;
// let deleteImg;
let avatarJson = {};
let seed = null;
let tags = '';
let textInterval;
let showPackageLoader = true;
let allowSave = false;
let getAvatarInterval;
const texts = [
    'Nie zamykaj tej karty, działamy',
    'Pamiętaj, że AI czasem leci w kulki',
    'To tylko zabawa, nie musi być idealnie ;)',
    'AI potrafi nieźle namieszać ;)',
    'Właśnie dzieje się magia',
    'Sztuczna Inteligencja właśnie myśli',
];

$(function () {
    lang = $('html').attr('lang');

    $('select[name="personal_data[age]"]').on('change', function () {
        const age = $(this).val();

        $('.statement-section').removeClass('d-none');

        if (age === 'under13') {
            $('.child-section').removeClass('d-none');
            $('.child-section').find('input').attr('required', true);
            $('.agreement-file').attr('href', '/files/zalacznik_4c.pdf');
            $('.youth-section').addClass('d-none');
            $('.youth-section').find('input').attr('required', false);
        } else {
            $('.child-section').addClass('d-none');
            $('.child-section').find('input').attr('required', false);
        }

        if (age !== 'under13') {
            if (age === 'between') {
                $('.youth-section').removeClass('d-none');
                $('.youth-section').find('input').attr('required', true);
                $('.agreement-file').attr('href', '/files/zalacznik_4b.pdf');
                $('.child-section').removeClass('d-none');
                $('.child-section').find('input').attr('required', true);
            } else {
                $('.youth-section').addClass('d-none');
                $('.youth-section').find('input').attr('required', false);
                $('.child-section').addClass('d-none');
                $('.child-section').find('input').attr('required', false);
            }
        }

        if (age === 'above18') {
            $('.adult-section').removeClass('d-none');
            $('.adult-section').find('input').attr('required', true);
            $('.agreement-file').attr('href', '/files/zalacznik_4a.pdf');
        } else {
            $('.adult-section').addClass('d-none');
            $('.adult-section').find('input').attr('required', false);
        }
    });

    $('a.agreement-file').on('click', function (e) {
        if (!$(this).attr('href')) {
            e.preventDefault();
        }
    });

    let params = new URL(document.location.toString()).searchParams;
    let name = params.get('clear');

    if (name === 'package') {
        eraseCookie('packageUuid');
        const newURL = location.href.split('?')[0];
        window.history.pushState('object', document.title, newURL);
    }

    $('.s-generator__options-container-color-item').on('click', function () {
        const imageSrc = $(this).attr('data-src');

        if (!$(this).hasClass('s-generator__options-container-item-active')) {
            $(this).closest('.s-generator__options-container').find('.s-generator__options-container-item-active').removeClass('s-generator__options-container-item-active');
            $(this).addClass('s-generator__options-container-item-active');

            $('.s-generator__package img').attr('src', imageSrc);
        }
    });

    $('#modal-instruction .next-instruction-step').on('click', function () {
        const index = $('#modal-instruction .instruction-content .step.show').index() + 1;
        const nextIndex = index + 1;

        $('#modal-instruction .instruction-step').text(nextIndex);

        $('#modal-instruction .instruction-content .step').removeClass('show');
        $(`#modal-instruction .instruction-content .step-${nextIndex}`).addClass('show');

        setTimeout(function () {
            $('#modal-instruction .instruction-content .step').removeClass('active');
            $(`#modal-instruction .instruction-content .step-${nextIndex}`).addClass('active');
        }, 200);

        if (nextIndex === 2) {
            $('#modal-instruction .prev-instruction-step').removeClass('disabled');
            $('#modal-instruction .next-instruction-step').removeClass('disabled');
        } else if (nextIndex === 1) {
            $('#modal-instruction .prev-instruction-step').addClass('disabled');
            $('#modal-instruction .next-instruction-step').removeClass('disabled');
        } else if (nextIndex === 3) {
            $('#modal-instruction .prev-instruction-step').removeClass('disabled');
            $('#modal-instruction .next-instruction-step').addClass('disabled');
        }
    });

    $('#modal-instruction .prev-instruction-step').on('click', function () {
        const index = $('#modal-instruction .instruction-content .step.show').index() + 1;
        const prevIndex = index - 1;

        $('#modal-instruction .instruction-step').text(prevIndex);

        $('#modal-instruction .instruction-content .step').removeClass('show');
        $(`#modal-instruction .instruction-content .step-${prevIndex}`).addClass('show');

        setTimeout(function () {
            $('#modal-instruction .instruction-content .step').removeClass('active');
            $(`#modal-instruction .instruction-content .step-${prevIndex}`).addClass('active');
        }, 150);

        if (prevIndex === 2) {
            $('#modal-instruction .prev-instruction-step').removeClass('disabled');
            $('#modal-instruction .next-instruction-step').removeClass('disabled');
        } else if (prevIndex === 1) {
            $('#modal-instruction .prev-instruction-step').addClass('disabled');
            $('#modal-instruction .next-instruction-step').removeClass('disabled');
        } else if (prevIndex === 3) {
            $('#modal-instruction .prev-instruction-step').removeClass('disabled');
            $('#modal-instruction .next-instruction-step').addClass('disabled');
        }
    });

    $('#select-packet, #go-to-assets').on('click', function () {
        $('.s-generator__options').removeClass('show');
        $('.s-generator__options.options-step-2').addClass('show');

        setTimeout(function () {
            $('.s-generator__options').removeClass('active');
            $('.s-generator__options.options-step-2').addClass('active');
        }, 250);
    });
    $('#back-to-colors').on('click', function () {
        $('.s-generator__options').removeClass('show');
        $('.s-generator__options.options-step-1').addClass('show');

        setTimeout(function () {
            $('.s-generator__options').removeClass('active');
            $('.s-generator__options.options-step-1').addClass('active');
        }, 250);
    });

    $('.s-winners__prizes button').on('click', function () {
        $('.s-winners__prizes button').removeClass('active');
        $(this).addClass('active');
        $('.s-winners__content').addClass('d-none');
        $('.s-winners__content-1').addClass('d-none');
        const week = $('.swiper-slide button.active').closest('.swiper-slide').attr('data-target');
        $($(this).attr('data-target')).removeClass('d-none');
        $($(this).attr('data-target')).find(week).removeClass('d-none');
    });

    $(document).on('scroll', function () {
        if ($(window).scrollTop() > 10) {
            $('#navigation').addClass('scrolling');
        } else {
            $('#navigation').removeClass('scrolling');
        }
    });

    if ($('#generator').length) {
        $('.s-generator__mobile-menu-item').on('click', function () {
            $('.s-generator__mobile-menu-active').removeClass('s-generator__mobile-menu-active');
            $(this).addClass('s-generator__mobile-menu-active');

            if ($(this).hasClass('s-generator__mobile-menu-avatar')) {
                $('.avatar-container').removeClass('invisible');
                $('.options-container').addClass('d-none');
                $('.background').removeClass('preview').removeClass('opened');
                $('.assets').removeClass('preview').removeClass('opened');
                $('#save-package').addClass('d-none');
                $('.go-to-form.mobile').addClass('d-none');
            } else if ($(this).hasClass('s-generator__mobile-menu-background')) {
                $('.avatar-container').addClass('invisible');
                $('.options-container').removeClass('d-none');
                $('.background').addClass('preview').removeClass('opened');
                $('.assets').removeClass('preview').removeClass('opened');
                $('#save-package').removeClass('d-none');
                $('.go-to-form.mobile').removeClass('d-none');
            } else if ($(this).hasClass('s-generator__mobile-menu-assets')) {
                $('.avatar-container').addClass('invisible');
                $('.options-container').removeClass('d-none');
                $('.background').removeClass('preview').removeClass('opened');
                $('.assets').addClass('preview').removeClass('opened');
                $('#save-package').removeClass('d-none');
                $('.go-to-form.mobile').removeClass('d-none');
            }
        });

        $('.more-options, .more-options-text').on('click', function () {
            if ($(this).closest('.s-generator__options').hasClass('opened')) {
                $(this).closest('.s-generator__options').removeClass('opened');
                $(this).closest('.s-generator__options-heading').find('span').text('Rozwiń');
            } else {
                $(this).closest('.s-generator__options').addClass('opened');
                $(this).closest('.s-generator__options-heading').find('span').text('Zwiń');
            }
        });

        $('#save-package').on('click', async function () {
            if ($('#nav-login-button').length && !allowSave) {
                $('#modal-login').modal('show');
                $('.login-form').attr('data-open', 'save');
            } else {
                if (showPackageLoader) {
                    $('#modal-loader').modal('show');
                }

                $(this).css({
                    'pointer-events': 'none',
                });
                // const token = $(this).attr('data-token');
                const token = $('#generator').attr('data-token');
                const json = JSON.stringify(canvas.toJSON());
                const lsUuid = readCookie('packageUuid');
                const uuid = $(this).attr('data-uuid')
                    ? `/${$(this).attr('data-uuid')}`
                    : lsUuid
                    ?`/${lsUuid}`
                    : '';

                try {
                    const packetCanvas = $('#packet-canvas')[0];

                    const packet = $('.s-generator__package').find('img');

                    const context = packetCanvas.getContext('2d');

                    context.drawImage(packet[0], 0, 0);

                    const assetImage = new Image();
                    let finallyImage;
                    assetImage.src = canvas.toDataURL('image/png');

                    assetImage.onload = function () {
                        context.drawImage(assetImage, 92, 324, 306, 270);

                        finallyImage = packetCanvas.toDataURL("image/png");
                    }
                    let formData = new FormData();
                    formData.set('recaptcha', await getRecaptcha());
                    formData.set('_token', token);
                    formData.set('colorImage', packet.attr('src'));
                    formData.set('image', finallyImage);
                    formData.set('json', json);

                    if (!showPackageLoader) {
                        formData.set('type', 'not_logged');
                    }

                    var data = await $.ajax({
                        type: 'post',
                        url: `/zapisz-paczke${uuid}`,
                        data: formData,
                        processData: false,
                        contentType: false,
                    });
                } catch (xhr) {
                    alert('Coś poszło nie tak, proszę spróbować jeszcze raz');

                    return;
                } finally {
                    // $(this.elements).filter('[type=submit]').prop('disabled', false);
                    if (showPackageLoader) {
                        $('#modal-loader').modal('hide');
                    }
                    $(this).css({
                        'pointer-events': 'auto',
                    });
                    allowSave = false;
                }

                $('#save-package').attr('data-uuid', data.uuid);
                if (showPackageLoader) {
                    $('#modal-saved-package').modal('show');
                }

                showPackageLoader = true;
            }
        });

        $('#canvas-container').on('dragover', function (e) {
            allowDrop(e);
        }).on('drop', function (e) {
            dropElement(e);
        });

        // $('.s-generator__options-container-asset-item img').on('dragstart', function (event) {
        //     dragElement(event);
        // });

        $('.s-generator__options-container-asset-item img').on('click', function (e) {
            if ($(this).hasClass('julka')) {
                drawImage2(e, e.target);
            } else if ($(this).hasClass('patec')) {
                drawImage3(e, e.target);
            } else {
                drawImage(e, e.target);
            }
        });

        $('.s-generator__package').find('canvas')[0].width = 306;
        $('.s-generator__package').find('canvas')[0].height = 270;

        canvas = new Canvas($('.s-generator__package').find('canvas')[0]);
        const packageJson = $('#canvas-container').attr('data-json');

        if (packageJson) {
            $('.s-generator__options-container-item').removeClass('s-generator__options-container-item-active');
            const color = $('.s-generator__package').find('img').attr('src');
            $(`.s-generator__options-container-item[data-src="${color}"]`).addClass('s-generator__options-container-item-active');

            canvas.loadFromJSON(packageJson, function (o, object) {
                setTimeout(() => {
                    setStyles();
                }, 500);
            });
        }

        if (!readCookie('packageUuid')) {
            if (!$('#save-package').attr('data-uuid')) {
                grecaptcha.ready(function () {
                    createPackage();
                });
            }
        }

        $('.go-to-form').on('click', function () {
            showPackageLoader = false;
            allowSave = true;
            $('#save-package').trigger('click');
            $('.fun-container').addClass('d-none');
            $('.form-container').removeClass('d-none');
            const uuid = $('#save-package').attr('data-uuid');
            const json = JSON.stringify(canvas.toJSON());
            const colorImage = $('.s-generator__package').find('img').attr('src');

            const packetCanvas = $('#packet-canvas')[0];

            const packet = $('.s-generator__package').find('img');

            const context = packetCanvas.getContext('2d');

            context.drawImage(packet[0], 0, 0);

            const assetImage = new Image();
            assetImage.crossOrigin = 'anonymous';
            assetImage.src = canvas.toDataURL('image/png');
            let finallyImage;

            assetImage.onload = function () {
                context.drawImage(assetImage, 92, 324, 306, 270);
                finallyImage = packetCanvas.toDataURL("image/png");

                $('.application-form').find('input[name="package_uuid"]').val(uuid);
                $('.application-form').find('input[name="package_photo"]').val(finallyImage);
                $('.application-form').find('input[name="package_color_photo"]').val(colorImage);
                $('.application-form').find('input[name="package_json"]').val(json);

                $('#form-package-img').attr('src', finallyImage);
            }
        });

        $('.avatar-options .avatar-option-container').on('click', function () {
            $('.avatar-options .avatar-option-container').removeClass('active');
            $(this).addClass('active');
            seed = $(this).find('img').attr('data-seed');
        });

        $('#modal-avatar .change-generator-view').on('click', async function (e) {
            const target = $(this).attr('data-target');
            const headingText = $(this).attr('data-heading');
            let chosenAvatar = $('#modal-avatar .generator-modal-container.active .avatar-option-container.active img').attr('src');

            if (target === '#avatar-characteristics') {
                chosenAvatar = $('.avatar-options .avatar-option-container.active').find('.avatar-option img').attr('src');

                if (chosenAvatar === '#') {
                    e.preventDefault();
                    return;
                }
            }

            $('#modal-avatar .generator-modal-container').removeClass('active');
            $(target).addClass('active');

            $('#modal-avatar .heading-text')
                .text(headingText);

            if ($(this).hasClass('next')) {
                $(target).find('.active').each(function () {
                    $(this).removeClass('active');
                });

                $(target).find('.clear-option').each(function () {
                    $(this).addClass('active');
                });

                $(target).find('.clear-accessory').each(function () {
                    $(this).addClass('active');
                });
            }

             /*if ($(this).attr('id') === 'select-characteristics-preview') {
                 chosenAvatar = $('#modal-avatar .avatar-characteristics-preview-container img').attr('src');
            } else*/ if ($(this).attr('id') === 'select-characteristics' || $(this).attr('id') === 'select-characteristics-preview') {
            // if ($(this).attr('id') === 'remove-background') {
                $(this).prop('disabled', true);
                if ($(this).attr('id') === 'select-accessories') {
                    chosenAvatar = $('#modal-avatar #avatar-accessories .chosen-avatar-container img').attr('src');
                } else {
                    chosenAvatar = $('#modal-avatar #avatar-accessories-preview .chosen-avatar-container img').attr('src');
                }

                if (!$('.avatar-options-container .avatar-options').find('.avatar-option-container.active').find('img').attr('src')) {
                    return;
                }

                $('.generator-modal-loader').find('h5').addClass('d-none');
                $('.generator-modal-loader').find('h3').text('Usuwamy tło...');
                $('.generator-modal-container.active').addClass('d-none');
                $('#modal-avatar .modal-header').addClass('d-none');
                $('.generator-modal-loader').removeClass('d-none');

                const self = this;
                try {
                    let formData = new FormData();
                    formData.set('recaptcha', await getRecaptcha());
                    // formData.set('_token', token);
                    formData.set('input_image', chosenAvatar);
                    formData.set('avatar', $('.avatar-options-container .avatar-options').find('.avatar-option-container.active').index());
                    formData.set('default', ($(this).attr('id') !== 'select-characteristics-preview'));
                    const uuid = $('#save-package').attr('data-uuid') ?? readCookie('packageUuid');

                    var data = await $.ajax({
                        type: 'post',
                        url: `/usun-tlo/${uuid}`,
                        data: formData,
                        processData: false,
                        contentType: false,
                    });
                } catch (xhr) {
                    alert('Coś poszło nie tak, proszę spróbować jeszcze raz');

                    $('.generator-modal-loader').addClass('d-none');
                    $('#modal-avatar .modal-header').removeClass('d-none');
                    $('#modal-avatar #avatar-characteristics').addClass('active');
                    // $('#modal-avatar #avatar-accessories').addClass('active');
                    // $('#modal-avatar #choose-avatar-container').addClass('active');

                    return;
                } finally {
                    // $('.generator-modal-loader').find('h3').text('Generujemy...');
                    // $('.generator-modal-loader').addClass('d-none');
                    // $('#modal-avatar .modal-header').removeClass('d-none');
                    // $(this).prop('disabled', false);
                    // $('.generator-modal-loader').find('h5').removeClass('d-none');
                }

                getAvatarInterval = setInterval(async function () {
                    try {
                        let formData = new FormData();
                        formData.set('recaptcha', await getRecaptcha());
                        const uuid = $('#save-package').attr('data-uuid') ?? readCookie('packageUuid');

                        var data = await $.ajax({
                            type: 'get',
                            url: `/status-avatara/${uuid}/4`,
                            data: formData,
                            processData: false,
                            contentType: false,
                        });
                    } catch (xhr) {
                        clearInterval(getAvatarInterval);
                        $('.generator-modal-loader').addClass('d-none');
                        $('#modal-avatar .modal-header').removeClass('d-none');
                        $('.generator-modal-container.active').removeClass('d-none');

                        if (xhr.responseJSON && xhr.responseJSON.error === 'wrongPhoto') {
                            $('.error-message')
                                .text('Zdjęcie, które przesłałeś, jest niezgodne z naszymi zasadami. Prosimy o przesłanie zgodnego pliku.')
                                .removeClass('d-none');

                            $('.generator-modal-container.active').removeClass('active');
                            $('.generator-modal-container#choose-avatar-container').addClass('active');
                        } else {
                            alert('Coś poszło nie tak, proszę spróbować jeszcze raz');
                        }

                        $('#remove-background').addClass('d-none');

                        return;
                    } finally {

                    }

                    if (data.status === 'success') {
                        clearInterval(getAvatarInterval);

                        $('.generator-modal-loader').find('h3').text('Generujemy...');
                        $('.generator-modal-loader').addClass('d-none');
                        $('#modal-avatar .modal-header').removeClass('d-none');
                        $(self).prop('disabled', false);
                        $('.generator-modal-loader').find('h5').removeClass('d-none');

                        if (data.avatar.url) {
                            $('#generator .s-generator__home-avatar-container .s-generator__avatar-container img').attr('src', data.avatar.url);
                        } else {
                            $('#generator .s-generator__home-avatar-container .s-generator__avatar-container img').attr('src', chosenAvatar);
                        }

                        const avatarWithoutBackground = new Image();
                        avatarWithoutBackground.crossOrigin = 'anonymous';
                        avatarWithoutBackground.src = data.avatar.url;
                        avatarWithoutBackground.onload = function () {
                            drawAvatarImage(avatarWithoutBackground);

                            $('.add-avatar-to-package').removeClass('d-none');
                        }

                        $('#modal-avatar').modal('hide');
                        $('#modal-avatar .generator-modal-container.active').removeClass('active');
                        $('.avatar-options-container-text').text('Albo wybierz z naszej kolekcji');
                        $('#modal-avatar #choose-avatar-container').addClass('active');

                        $('#generate-characteristics').addClass('d-none');
                        $('#select-characteristics').removeClass('d-none');
                    }
                }, 2000);
            }

            $('#modal-avatar').find('.chosen-avatar-container img').attr('src', chosenAvatar);
        });

        $('#modal-avatar .avatar-generator-option-item').on('click', function () {
            $(this).closest('.avatar-generator-option-items').find('.active').removeClass('active');
            $(this).addClass('active');

            if ($('#modal-avatar .avatar-generator-option-item.clear-option.active').length < 3) {
                $('#select-characteristics').addClass('d-none');
                $('#generate-characteristics').removeClass('d-none');
            } else {
                $('#generate-characteristics').addClass('d-none');
                $('#select-characteristics').removeClass('d-none');
            }

            getComputedStyle(this).getPropertyValue('--avatar-item-color');
        });

        $('#modal-avatar .avatar-generator-accessory-item').on('click', function () {
            $(this).closest('.avatar-generator-accessory-items').find('.active').removeClass('active');
            $(this).addClass('active');

            if ($(this).closest('#hat-accessories').length && $(this).attr('data-name')) {
                $('#headphones-accessories').find('.active').removeClass('active');
                $('#headphones-accessories').find('.clear-accessory').addClass('active');
            } else if ($(this).closest('#headphones-accessories').length && $(this).attr('data-name')) {
                $('#hat-accessories').find('.active').removeClass('active');
                $('#hat-accessories').find('.clear-accessory').addClass('active');
            }

            if ($('#modal-avatar .avatar-generator-accessory-item.clear-option.active').length < 3) {
                $('#select-accessories').addClass('d-none');
                $('#generate-accessories').removeClass('d-none');
            } else {
                $('#generate-accessories').addClass('d-none');
                $('#select-accessories').removeClass('d-none');
            }

            const imgSrc = $(this).attr('src');
        });

        $('#modal-avatar input[type="file"]').on('change', function (e) {
            const file = e.target.files[0];

            const reader = new FileReader();
            let originalUploadedImage;

            reader.onload = (event) => {
                avatarJson = {};
                seed = null;
                tags = '';
                // $(preview).prop("src", event.target.result);
                const target = '#avatar-cropper';
                const headingText = 'Dostosuj swoje zdjęcie';

                $('#modal-avatar .generator-modal-container').removeClass('active');
                $(target).addClass('active');

                $('#modal-avatar .heading-text')
                    .text(headingText);

                originalUploadedImage = event.target.result;
                const crop = Cropper(document.getElementById('crop-image'), {
                    // area: [ 298, 298 ],
                    // crop: [ 254, 254 ],
                    area: [ 1024, 1024 ],
                    crop: [ 1024, 1024 ],
                    value: event.target.result,
                    eventListeners: {
                        onchange: function (e) {
                            console.log(e);
                        }
                    }
                });

                $('#modal-avatar input[type="file"]').val('');
            };
            reader.readAsDataURL(file);

            document.getElementById('zoom').oninput = function() {
                document.getElementById('crop-image').crop.zoom(this.value);
            }

            document.getElementById('rotate').oninput = function() {
                document.getElementById('crop-image').crop.rotate(this.value);
            }
        });

        $('.crop-avatar-button').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).prop('disabled', true);
            const self = this;
            const originalCropCanvas = $('#original-crop')[0];
            const croppedImage = document.getElementById('crop-image').crop.getCroppedImage().src;

            const context = originalCropCanvas.getContext('2d');
            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = "high";
            const originalCrop = new Image(1024, 1024);
            originalCrop.crossOrigin = 'anonymous';
            originalCrop.src = croppedImage;

            originalCrop.onload = async function () {
                context.drawImage(originalCrop, 0, 0, 1024, 1024);
                const finallyImage = originalCropCanvas.toDataURL("image/png", 1.0);

                $('.generator-modal-container.active').addClass('d-none');
                $('#modal-avatar .modal-header').addClass('d-none');
                $('.generator-modal-loader').find('h5').text('Generowanie avatara zwykle trwa ok 10 sekund');
                $('.generator-modal-loader').removeClass('d-none');
                showLoaderText();

                try {
                    let formData = new FormData();
                    formData.set('recaptcha', await getRecaptcha());
                    // formData.set('_token', token);
                    formData.set('image', finallyImage);
                    formData.set('sex', $('input[name="avatarSex"]:checked').val());
                    const uuid = $('#save-package').attr('data-uuid') ?? readCookie('packageUuid');

                    var data = await $.ajax({
                        type: 'post',
                        url: `/generuj/1/${uuid}`,
                        data: formData,
                        processData: false,
                        contentType: false,
                    });
                } catch (xhr) {
                    $('.generator-modal-loader').addClass('d-none');
                    $('#modal-avatar .modal-header').removeClass('d-none');
                    $('.generator-modal-container.active').removeClass('d-none');

                    if (xhr.responseJSON && xhr.responseJSON.error === 'wrongPhoto') {
                        // console.log(xhr.responseJSON.result)
                        $('.error-message')
                            .text('Zdjęcie, które przesłałeś, jest niezgodne z naszymi zasadami. Prosimy o przesłanie zgodnego pliku.')
                            .removeClass('d-none');

                        $('.generator-modal-container.active').removeClass('active');
                        $('.generator-modal-container#choose-avatar-container').addClass('active');
                    } else {
                        alert('Coś poszło nie tak, proszę spróbować jeszcze raz');
                    }

                    $('#remove-background').addClass('d-none');

                    return;
                } finally {
                    $(self).prop('disabled', false);
                }

                $('.error-message').addClass('d-none');

                getAvatarInterval = setInterval(async function () {
                    try {
                        let formData = new FormData();
                        formData.set('recaptcha', await getRecaptcha());
                        formData.set('image', finallyImage);
                        formData.set('sex', $('input[name="avatarSex"]:checked').val());
                        const uuid = $('#save-package').attr('data-uuid') ?? readCookie('packageUuid');

                        var data = await $.ajax({
                            type: 'get',
                            url: `/status-avatara/${uuid}/1`,
                            data: formData,
                            processData: false,
                            contentType: false,
                        });
                    } catch (xhr) {
                        clearInterval(getAvatarInterval);
                        $('.generator-modal-loader').addClass('d-none');
                        $('#modal-avatar .modal-header').removeClass('d-none');
                        $('.generator-modal-container.active').removeClass('d-none');

                        if (xhr.responseJSON && xhr.responseJSON.error === 'wrongPhoto') {
                            $('.error-message')
                                .text('Zdjęcie, które przesłałeś, jest niezgodne z naszymi zasadami. Prosimy o przesłanie zgodnego pliku.')
                                .removeClass('d-none');

                            $('.generator-modal-container.active').removeClass('active');
                            $('.generator-modal-container#choose-avatar-container').addClass('active');
                        } else {
                            alert('Coś poszło nie tak, proszę spróbować jeszcze raz');
                        }

                        $('#remove-background').addClass('d-none');

                        return;
                    } finally {
                        $(self).prop('disabled', false);
                        clearLoaderText();
                    }

                    if (data.status === 'success') {
                        clearInterval(getAvatarInterval);

                        seed = data.seed;
                        tags = data.tags;

                        $('.avatar-options-container-text').text('Wybierz avatar');

                        if (data.avatars.length) {
                            $('.avatar-options-container-text').removeClass('d-none');
                            $('.avatar-options').removeClass('d-none');
                            $('#modal-avatar .avatar-options .avatar-option-container').each(function (key, item) {
                                if (data.avatars[key]) {
                                    $(this).removeClass('d-none');
                                    $(this).find('img').attr('src', data.avatars[key].url).attr('data-seed', data.avatars[key].seed).removeClass('d-none');
                                } else {
                                    $(this).addClass('d-none');
                                }
                            });

                            $('#remove-background').removeClass('d-none');
                        } else {
                            alert('Coś poszło nie tak, proszę spróbować jeszcze raz');
                            $('.generator-modal-loader').addClass('d-none');
                            $('#modal-avatar .modal-header').removeClass('d-none');
                            $('.generator-modal-container.active').removeClass('d-none');
                            $('#remove-background').addClass('d-none');
                        }

                        $('.generator-modal-loader').addClass('d-none');
                        $('#modal-avatar .modal-header').removeClass('d-none');
                        $('.generator-modal-container.active').removeClass('d-none').removeClass('active');
                        $('#choose-avatar-container').addClass('active');
                    }
                }, 2000);
            }
        });

        $('#generate-characteristics').on('click', async function (e) {
            $(this).prop('disabled', true);
            e.preventDefault();
            e.stopPropagation();

            $('.generator-modal-container.active').addClass('d-none');
            $('#modal-avatar .modal-header').addClass('d-none');
            $('.generator-modal-loader').removeClass('d-none');
            const hair = $('#hair-options .avatar-generator-option-item.active').attr('data-name');
            const skin = $('#skin-options .avatar-generator-option-item.active').attr('data-name');
            const eye = $('#eyes-options .avatar-generator-option-item.active').attr('data-name');

            if (avatarJson[seed] && avatarJson[seed][`${hair}_${skin}_${eye}`]) {
                $('.generator-modal-loader').addClass('d-none');
                $('#modal-avatar .modal-header').removeClass('d-none');
                $('.generator-modal-container.active').removeClass('d-none').removeClass('active');

                $('#avatar-characteristics-preview').addClass('active');
                $('#avatar-characteristics-preview .avatar-characteristics-preview-container')
                    .find('img')
                    .attr('src', avatarJson[seed][`${hair}_${skin}_${eye}`]);

                $(this).prop('disabled', false);
            } else {
                showLoaderText();
                try {
                    let formData = new FormData();
                    formData.set('recaptcha', await getRecaptcha());
                    // formData.set('_token', token);
                    formData.set('seed', seed);
                    formData.set('tags', tags);
                    formData.set('hair_color', hair);
                    formData.set('skin_color', skin);
                    formData.set('eye_color', eye);
                    formData.set('avatar', $('.avatar-options-container .avatar-options').find('.avatar-option-container.active').index());
                    var url_string = window.location.href;
                    var url = new URL(url_string);
                    var c = url.searchParams.get("value") ?? '0.3';

                    formData.set('controlnet_strenght', c);
                    const uuid = $('#save-package').attr('data-uuid') ?? readCookie('packageUuid');

                    var data = await $.ajax({
                        type: 'post',
                        url: `/zmiana-cech/${uuid}`,
                        data: formData,
                        processData: false,
                        contentType: false,
                    });
                } catch (xhr) {
                    alert('Coś poszło nie tak, proszę spróbować jeszcze raz');

                    $('.generator-modal-loader').addClass('d-none');
                    $('#modal-avatar .modal-header').removeClass('d-none');
                    $('.generator-modal-container.active').removeClass('d-none');

                    return;
                } finally {
                    $(this).prop('disabled', false);

                    // clearLoaderText();
                }

                getAvatarInterval = setInterval(async function () {
                    try {
                        let formData = new FormData();
                        formData.set('recaptcha', await getRecaptcha());
                        const uuid = $('#save-package').attr('data-uuid') ?? readCookie('packageUuid');

                        var data = await $.ajax({
                            type: 'get',
                            url: `/status-avatara/${uuid}/2`,
                            data: formData,
                            processData: false,
                            contentType: false,
                        });
                    } catch (xhr) {
                        clearInterval(getAvatarInterval);
                        $('.generator-modal-loader').addClass('d-none');
                        $('#modal-avatar .modal-header').removeClass('d-none');
                        $('.generator-modal-container.active').removeClass('d-none');

                        if (xhr.responseJSON && xhr.responseJSON.error === 'wrongPhoto') {
                            $('.error-message')
                                .text('Zdjęcie, które przesłałeś, jest niezgodne z naszymi zasadami. Prosimy o przesłanie zgodnego pliku.')
                                .removeClass('d-none');

                            $('.generator-modal-container.active').removeClass('active');
                            $('.generator-modal-container#choose-avatar-container').addClass('active');
                        } else {
                            alert('Coś poszło nie tak, proszę spróbować jeszcze raz');
                        }

                        $('#remove-background').addClass('d-none');

                        return;
                    } finally {
                        $(self).prop('disabled', false);
                        clearLoaderText();
                    }

                    if (data.status === 'success') {
                        clearInterval(getAvatarInterval);

                        $('.generator-modal-loader').addClass('d-none');
                        $('#modal-avatar .modal-header').removeClass('d-none');
                        $('.generator-modal-container.active').removeClass('d-none').removeClass('active');

                        if (data.avatar.url) {
                            $('#avatar-characteristics-preview').addClass('active');
                            $('#avatar-characteristics-preview .avatar-characteristics-preview-container').find('img').attr('src', data.avatar.url);

                            const avatarJsonIndex = `${hair}_${skin}_${eye}`;
                            const avatarJsonValue = {[avatarJsonIndex]: data.avatar.url};
                            if (!avatarJson[seed]) {
                                avatarJson[seed] = avatarJsonValue;
                            } else {
                                avatarJson[seed] = {...avatarJson[seed], ...avatarJsonValue};
                            }
                        } else {
                            alert('Coś poszło nie tak, proszę spróbować jeszcze raz');
                            $('.generator-modal-loader').addClass('d-none');
                            $('#modal-avatar .modal-header').removeClass('d-none');
                            $('.generator-modal-container.active').removeClass('d-none');
                        }
                    }
                }, 2000);
            }
        });

        $('#generate-accessories').on('click', async function (e) {
            $(this).prop('disabled', true);
            e.preventDefault();
            e.stopPropagation();

            $('.generator-modal-container.active').addClass('d-none');
            $('#modal-avatar .modal-header').addClass('d-none');
            $('.generator-modal-loader').removeClass('d-none');
            const hair = $('#hair-options .avatar-generator-option-item.active').attr('data-name');
            const skin = $('#skin-options .avatar-generator-option-item.active').attr('data-name');
            const eye = $('#eyes-options .avatar-generator-option-item.active').attr('data-name');
            const headphones = $('#headphones-accessories .avatar-generator-accessory-item.active').attr('data-name');
            const hat = $('#hat-accessories .avatar-generator-accessory-item.active').attr('data-name');
            const eyeglasses = $('#glasses-accessories .avatar-generator-accessory-item.active').attr('data-name');

            if (avatarJson[seed] && avatarJson[seed][`${hair}_${skin}_${eye}_${headphones}_${hat}_${eyeglasses}`]) {
                $('.generator-modal-loader').addClass('d-none');
                $('#modal-avatar .modal-header').removeClass('d-none');
                $('.generator-modal-container.active').removeClass('d-none').removeClass('active');

                $('#avatar-accessories-preview').addClass('active');
                $('#avatar-accessories-preview .avatar-accessories-preview-container')
                    .find('img')
                    .attr('src', avatarJson[seed][`${hair}_${skin}_${eye}_${headphones}_${hat}_${eyeglasses}`]);

                $(this).prop('disabled', false);
            } else {
                showLoaderText();
                try {
                    let formData = new FormData();
                    formData.set('recaptcha', await getRecaptcha());
                    // formData.set('_token', token);
                    formData.set('seed', seed);
                    formData.set('tags', tags);
                    formData.set('hair_color', hair);
                    formData.set('skin_color', skin);
                    formData.set('eye_color', eye);
                    formData.set('headphones', headphones);
                    formData.set('hat', hat);
                    formData.set('eyeglasses', eyeglasses);
                    formData.set('avatar', $('.avatar-options-container .avatar-options').find('.avatar-option-container.active').index());

                    var url_string = window.location.href;
                    var url = new URL(url_string);
                    var c = url.searchParams.get("value") ?? '0.3';

                    formData.set('controlnet_strenght', c);
                    const uuid = $('#save-package').attr('data-uuid') ?? readCookie('packageUuid');

                    var data = await $.ajax({
                        type: 'post',
                        url: `/zmiana-akcesoriow/${uuid}`,
                        data: formData,
                        processData: false,
                        contentType: false,
                    });
                } catch (xhr) {
                    alert('Coś poszło nie tak, proszę spróbować jeszcze raz');

                    $('.generator-modal-loader').addClass('d-none');
                    $('#modal-avatar .modal-header').removeClass('d-none');
                    $('.generator-modal-container.active').removeClass('d-none');

                    return;
                } finally {
                    $(this).prop('disabled', false);
                    clearLoaderText();
                }

                $('.generator-modal-loader').addClass('d-none');
                $('#modal-avatar .modal-header').removeClass('d-none');
                $('.generator-modal-container.active').removeClass('d-none').removeClass('active');

                if (data.avatar.url) {
                    $('#avatar-accessories-preview').addClass('active');
                    $('#avatar-accessories-preview .avatar-accessories-preview-container').find('img').attr('src', data.avatar.url);

                    const avatarJsonIndex = `${hair}_${skin}_${eye}_${headphones}_${hat}_${eyeglasses}`;
                    const avatarJsonValue = { [avatarJsonIndex]: data.avatar.url };
                    if (!avatarJson[seed]) {
                        avatarJson[seed] = avatarJsonValue;
                    } else {
                        avatarJson[seed] = { ...avatarJson[seed], ...avatarJsonValue };
                    }
                } else {
                    alert('Coś poszło nie tak, proszę spróbować jeszcze raz');
                    $('.generator-modal-loader').addClass('d-none');
                    $('#modal-avatar .modal-header').removeClass('d-none');
                    $('.generator-modal-container.active').removeClass('d-none');
                }
            }
        });

        $('#create-account').on('change', function () {
            if ($(this).is(':checked')) {
                $('.create-account-section').removeClass('d-none');
                $('.create-account-section').find('input').each(function () {
                    $(this).attr('required', true).attr('disabled', false);
                });
            } else {
                $('.create-account-section').addClass('d-none');
                $(this).attr('required', false).attr('disabled', true);
            }
        });

        $('.prev-package-asset-step').on('click', function () {
            const currentStep = parseInt($('.package-asset-step.active').attr('data-step'), 10);

            $('.next-package-asset-step').removeClass('disabled');

            if (currentStep > 1) {
                const prevStep = currentStep - 1;
                $('.package-asset-step.active').removeClass('active');
                $(`.package-asset-step[data-step="${prevStep}"]`).addClass('active');
                $('.package-asset-step-container .package-asset-step-count').text(prevStep);
            } else {
                $('.prev-package-asset-step').addClass('disabled');
            }
        });

        $('.next-package-asset-step').on('click', function () {
            const currentStep = parseInt($('.package-asset-step.active').attr('data-step'), 10);

            $('.prev-package-asset-step').removeClass('disabled');

            if (currentStep < $('.package-asset-step').length) {
                const nextStep = currentStep + 1;
                $('.package-asset-step.active').removeClass('active');
                $(`.package-asset-step[data-step="${nextStep}"]`).addClass('active');
                $('.package-asset-step-container .package-asset-step-count').text(nextStep);
            } else {
                $('.next-package-asset-step').addClass('disabled');
            }
        });

        $('#asset-modification-zoom-in').on('click', function () {
            const activeItem = canvas.getActiveObject();

            if (activeItem) {
                activeItem.scale(activeItem.scaleX + 0.1);

                canvas.requestRenderAll();
            }
        });

        $('#asset-modification-zoom-out').on('click', function () {
            const activeItem = canvas.getActiveObject();

            if (activeItem) {
                activeItem.scale(activeItem.scaleX - 0.1);

                canvas.requestRenderAll();
            }
        });

        $('#asset-modification-rotate-left').on('click', function () {
            const activeItem = canvas.getActiveObject();

            if (activeItem) {
                activeItem.rotate(activeItem.angle - 6);
                activeItem.setCoords();

                canvas.requestRenderAll();
            }
        });

        $('#asset-modification-rotate-right').on('click', function () {
            const activeItem = canvas.getActiveObject();

            if (activeItem) {
                activeItem.rotate(activeItem.angle + 6);
                activeItem.setCoords();

                canvas.requestRenderAll();
            }
        });

        $('#asset-modification-delete').on('click', function () {
            const activeItem = canvas.getActiveObject();

            if (activeItem) {
                canvas.remove(activeItem);
                assetIndex--;
                canvas.requestRenderAll();
            }
        });

        let yDown = null;

        $('.s-generator__options-container').on('touchstart', function (e) {
            yDown = e.changedTouches[0].screenY;
        });

        $('.s-generator__options-container').on('touchmove', function (e) {
            let yUp = e.changedTouches[0].screenY;
            let yDiff = yDown - yUp;
            e.preventDefault();
            e.stopPropagation();
            if ( yDiff > 0 ) {
                if (!$(this).closest('.s-generator__options.opened').length) {
                    $(this).closest('.s-generator__options').addClass('opened');
                    $(this).find('.s-generator__options-heading').find('span').text('Zwiń');
                }
            } else {
                if ($(this).closest('.s-generator__options.opened').length) {
                    $(this).closest('.s-generator__options').removeClass('opened');
                    $(this).find('.s-generator__options-heading').find('span').text('Rozwiń');
                }
            }
        });

        $('.add-avatar-to-package').on('click', function () {
            drawAvatarImage($('.s-generator__avatar-container').find('img')[0]);
        });
    }

    $('.account-buttons button').on('click', function () {
        $('.account-buttons button').removeClass('active');
        $(this).addClass('active');
        const target = $(this).attr('data-target');

        if ($(target).length) {
            $('.account-item').removeClass('active').removeClass('show');
            $(target).addClass('active').addClass('show');
        }
    });

    // $('#my-works .my-package .package-status.new').on('click', function () {
    //     window.location.href = $(this).attr('data-url');
    // });

    $('#my-works .my-package .remove-package').on('click', function (e) {
        e.preventDefault();

        $('#modal-confirm-delete-package').find('img.package-img').attr('src', $(this).closest('.my-package').find('img').attr('src'));
        $('#modal-confirm-delete-package').find('a.delete-package').attr('href', `/usun-paczke/${$(this).attr('data-id')}`);
        $('#modal-confirm-delete-package').find('a.delete-package').attr('data-token', $(this).attr('data-token'));

        $('#modal-confirm-delete-package').modal('show');
    });

    $('#modal-confirm-delete-package a.delete-package').on('click', async function (e) {
        e.preventDefault();

        const token = $(this).attr('data-token');
        const url = $(this).attr('href');

        try {
            let formData = new FormData();
            formData.set('recaptcha', await getRecaptcha());
            formData.set('_token', token);

            var data = await $.ajax({
                type: 'post',
                url: url,
                data: formData,
                processData: false,
                contentType: false,
            });
        } catch (xhr) {

            return;
        } finally {
            // $(this.elements).filter('[type=submit]').prop('disabled', false);
            $('#modal-confirm-delete-package').modal('hide');
        }

        if (data.id) {
            if (data.uuid === readCookie('packageUuid')) {
                eraseCookie('packageUuid');
            }
            $(`.my-package .remove-package[data-id="${data.id}"]`).closest('.my-package').fadeOut();

            setTimeout(function () {
                $(`.my-package .remove-package[data-id="${data.id}"]`).closest('.my-package').remove()
            }, 250);
        }
    });

    $('.package-status').on('click', function () {
        const image = $(this).closest('.my-package').find('img').attr('src');
        let target;
        let status;

        switch ($(this).attr('data-status')) {
            case 'win':
                target = '#modal-win';
                status = 'Wygrane'
                break;
            case 'submitted':
                target = '#modal-submitted';
                status = 'Zgłoszone'
                break;
            case 'rejected':
                target = '#modal-rejected';
                status = 'Odrzucone'
                break;
            default:
                target = '#modal-new';
                status = 'Nie zgłoszone'
        }

        const editUrl = $(this).attr('data-url');

        if (editUrl) {
            $('#modal-new').find('.edit-package').attr('href', editUrl);
        }

        $('.my-package-modal').find('.package-container img').attr('src', image);

        $('.my-package-modal').find('.application-item-id').text($(this).attr('data-uuid'));
        $('.my-package-modal').find('.application-item-status').text(status);
        $('.my-package-modal').find('.application-item-date').text($(this).attr('data-published'));

        $(target).modal('show');
    });
});

function allowDrop(event) {
    event.preventDefault();
}

function dragElement(event)
{
    const tagName = event.target.tagName.toUpperCase();

    if (tagName === "IMG" ) {
        source = event.target;
    }
}

function dropElement(event) {
    event.preventDefault();

    if (source === undefined) {
        return;
    }

    if (source.tagName === "IMG") {
        drawImage(event, source);
    }
}

function drawImage(event, img) {
    const image = new FabricImage(img, { left: 220, top: 40,  width: img.naturalWidth, height: img.naturalHeight });
    canvas.add(image);

    setStyles(.5);
}

function drawImage2(event, img) {
    const image = new FabricImage(img, { left: 180, top: 40,  width: img.naturalWidth, height: img.naturalHeight });
    canvas.add(image);

    setStyles(.4);
}

function drawImage3(event, img) {
    const image = new FabricImage(img, { left: 160, top: 40,  width: img.naturalWidth, height: img.naturalHeight });
    canvas.add(image);

    setStyles(.3);
}

function drawAvatarImage(img) {
    const image = new FabricImage(img, { left: -64, top: 12,  width: img.naturalWidth, height: img.naturalHeight });
    canvas.add(image);

    setStyles(.25);
}

function deleteObject(eventData, transform) {
    var target = transform.target;
    var canvas = target.canvas;
    canvas.remove(target);
    assetIndex--;
    canvas.requestRenderAll();
}

function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    const deleteIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFVSURBVHgBrVXBcYMwEDycPPIDOhAVhBIogRKgAugg6QCogKQCQgWoA+gAOoBvXsqdAh7b6MBjvDOLJWvYW92dhAVriJu5M3MP0vRnghyR6kH2t4Y+Dohdkkw5L7P6DzwHb8jfE6xzeBT+iV3xfQiCALIsA6XUmW3bQhRFEIYhOI6xfjY9AjDkZxxHtQVax8CmvDavnFPP87RbghBitT4MA3Rdx71udopb3HRaliXXAQ2b0yPYFaVtxnEM0zRBURSah0VJTEqpc5ymKWCB9l7hRUmMQMWi9qE5FYyECa7rwpbosCVa1zXkeQ5Jkug5pYJg2zanKZbHqorY+Od+rKpKj/u+V03T6DH9AnOxsH26gE4NbV9Hx+2bepazu4qIYtoZd5owEOvUgv8LeL+k90NSoagiEp6H72VAh/zIrb/wi8SsiwgC+Yl8h+tv0jRzCwOyhvmy/wNCBS5Ju4/H/QAAAABJRU5ErkJggg==";

    const deleteImg = new Image();
    deleteImg.src = deleteIcon
    deleteImg.onload = function () {
        var size = 20;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(deleteImg, -size/2, -size/2, size, size);
        ctx.restore();
        // ctx.drawImage(deleteImg, -size/2, -size/2, size, size);
    }
}

function setStyles(scale) {
    canvas.item(assetIndex)['transparentCorners'] = false;
    canvas.item(assetIndex)['cornerSize'] = 8;
    canvas.item(assetIndex)['setControlVisible']('tl', false);
    canvas.item(assetIndex)['setControlVisible']('bl', false);
    canvas.item(assetIndex)['setControlVisible']('br', false);
    canvas.item(assetIndex)['setControlVisible']('ml', false);
    canvas.item(assetIndex)['setControlVisible']('mb', false);
    canvas.item(assetIndex)['setControlVisible']('mr', false);
    canvas.item(assetIndex)['setControlVisible']('mt', false);
    canvas.item(assetIndex)['borderColor'] = '#00B5EB';
    canvas.item(assetIndex)['cornerColor'] = '#000000';
    canvas.item(assetIndex)['cornerStrokeColor'] = '#000000';
    canvas.item(assetIndex).controls.mtr.offsetY = -16;

    canvas.item(assetIndex).controls.deleteControl = new Control({
        x: 0.3,
        y: .4,
        offsetY: 20,
        cursorStyle: 'pointer',
        mouseUpHandler: deleteObject,
        render: renderIcon,
        cornerSize: 20
    });

    if (scale) {
        canvas.item(assetIndex).scale(scale);
    }

    assetIndex++;

    canvas.renderAll();
}

async function createPackage () {
    const token = $('#generator').attr('data-token');
    const json = JSON.stringify(canvas.toJSON());
    const uuid = '';

    try {
        const packetCanvas = $('#packet-canvas')[0];

        const packet = $('.s-generator__package').find('img');

        const context = packetCanvas.getContext('2d');

        context.drawImage(packet[0], 0, 0);

        const assetImage = new Image();
        let finallyImage;
        assetImage.crossOrigin = 'anonymous';
        assetImage.src = canvas.toDataURL('image/png');

        assetImage.onload = function () {
            context.drawImage(assetImage, 92, 324, 306, 270);

            finallyImage = packetCanvas.toDataURL("image/png");
        }
        let formData = new FormData();
        formData.set('recaptcha', await getRecaptcha());
        formData.set('_token', token);
        formData.set('colorImage', packet.attr('src'));
        formData.set('image', finallyImage);
        formData.set('json', json);
        formData.set('type', 'not_logged');
        formData.set('type', 'not_logged');
        var data = await $.ajax({
            type: 'post',
            url: `/zapisz-paczke${uuid}`,
            data: formData,
            processData: false,
            contentType: false,
        });
    } catch (xhr) {

        return;
    } finally {
        // $(this.elements).filter('[type=submit]').prop('disabled', false);
    }

    createCookie('packageUuid', data.uuid, 1);
    $('#save-package').attr('data-uuid', data.uuid);
}

$('.application-form').on('submit-success', function(event, data) {
    $('#modal-thank-you').find('.application-uuid').text(data.uuid);
    $('#modal-thank-you').modal('show');

    const newURL = location.href.split('/')[0] + '/generator';
    window.history.pushState('object', document.title, newURL);

    $('.form-container').addClass('d-none');
    $('.fun-container').removeClass('d-none');

    eraseCookie('packageUuid');
});

function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

function showLoaderText() {
    textInterval = setInterval(function () {
        const textToShow = texts[0];
        $('.generator-modal-loader').find('h5').text(textToShow);
        texts.shift();
        texts.push(textToShow);
    }, 3000);
}

function clearLoaderText() {
    clearInterval(textInterval);
}
